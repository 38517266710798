import { useState, useEffect } from "react";

const useLocalStorage = (localStorageKey) => {
	const [value, setValue] = useState(null);

	useEffect(() => {
		if (typeof window !== "undefined") {
			setValue(JSON.parse(localStorage.getItem(localStorageKey)));
		}
	}, []);

	const updateValue = (updatedValue) => {
		if (typeof window !== "undefined") {
			try {
				localStorage.setItem(localStorageKey, JSON.stringify(updatedValue));
			} catch (error) {
				console.log(error);
			}
		}
	};

	const getvalue = () => {
		return JSON.parse(localStorage.getItem(localStorageKey));
	};

	return { value, updateValue, getvalue };
};

export default useLocalStorage;
