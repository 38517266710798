import styled, { ThemeProps } from "styled-components";
import { bp, createNestedStyledVariants, css, ThemeBase } from "theme";

type ButtonProps = {
	outline?: boolean;
	hasBorderRadius?: boolean;
	link?: boolean;
	icon?: boolean;
	size?: "default" | "small" | "medium" | "large";
	block?: boolean;
	variant: "primary" | "secondary" | "outlined";
	onClick?: Function;
};

type PropsType = ThemeProps<ThemeBase> & ButtonProps;

const ApnaButtonBaseStyles = css`
	height: 42px;
	border-radius: 8px;
	font-weight: ${(props) => props.theme.fontWeight.semiBold};
	font-size: ${(props) => props.theme.fontSize.sm};
	text-align: center;
	padding: 0 12px;
	outline: none;
	cursor: pointer;
	transition-duration: 200ms;
	transition-property: all;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-family: ${(props) => props.theme.fontFamily};
	font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const ApnaSmallButtonStyles = css`
	padding: 0 8px;
	border-radius: 2px;
	height: 32px;
	font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const ApnaMediumButtonStyles = css<PropsType>`
	padding: 0 12px;
	height: 40px;
	border: 2px solid ${(props) => props.theme.colors.tertiary};
	${(props) =>
		props.variant === "secondary" &&
		css`
			border: 2px solid ${props.theme.colors.primary};
		`}
`;

const ApnaLargeButtonStyles = css`
	height: 48px;
	font-size: ${(props) => props.theme.fontSize.base};
	line-height: 1;
`;

const ApnaWithIconButtonStyles = css`
	> * + * {
		margin-left: 4px;
	}
`;

const VIButtonBaseStyles = css`
	height: 42px;
	min-width: max-content;
	line-height: 1;
	border-radius: 4px;
	font-weight: ${(props) => props.theme.fontWeight.semiBold};
	font-size: ${(props) => props.theme.fontSize.sm};
	text-align: center;
	padding: 0 12px;
	outline: none;
	cursor: pointer;
	transition-duration: 200ms;
	transition-property: all;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => props.theme.colors.backgroundPrimary};
	font-family: ${(props) => props.theme.fontFamily};
`;

const VISmallButtonStyles = css`
	height: 32px;
	padding: 0 10px;
`;

const VIMediumButtonStyles = css`
	padding: 0 10px;
`;

const VILargeButtonStyles = css`
	padding: 0 20px;
`;

const VIWithIconButtonStyles = css`
	> * + * {
		margin-left: 4px;
	}
`;

const ButtonVariants = createNestedStyledVariants({
	apna: {
		primary: css<PropsType>`
			${ApnaButtonBaseStyles}
			background: ${({ theme, outline }) =>
				outline ? "transparent" : theme.colors.tertiary};
			border: 1px solid ${(props) => props.theme.colors.tertiary};
			width: ${(props) => (props.block ? "100%" : "auto")};
			${(props) =>
				props.outline &&
				css`
					color: ${props.theme.colors.tertiary};
				`}
			${(props) => props.size === "large" && ApnaLargeButtonStyles}
			${(props) => props.size === "medium" && ApnaMediumButtonStyles}
			${(props) => props.size === "small" && ApnaSmallButtonStyles}
			${(props) => props.icon && ApnaWithIconButtonStyles}
			
			${(props) =>
				props.hasBorderRadius &&
				css`
					border-radius: 0;
				`}

			&:hover {
				box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
					0px 8px 8px -4px rgba(24, 39, 75, 0.08);
				background: ${({ theme, outline }) =>
					outline ? theme.colors.tertiary : "transparent"};
				color: ${({ theme, outline }) =>
					outline ? "#ffffff" : theme.colors.tertiary};
			}

			${(props) =>
				props.link &&
				css`
					color: ${props.theme.colors.tertiary};
					height: fit-content;
					border: none;
					background: inherit;
					min-width: auto;

					&:hover {
						box-shadow: none;
						color: ${props.theme.colors.primaryBlack};
					}
				`}

			&:disabled {
				background: #dae0e5;
				border: none;
			}
		`,
		secondary: css<PropsType>`
			${ApnaButtonBaseStyles}
			background: ${({ theme, outline }) =>
				outline ? "transparent" : theme.colors.primary};
			border: 1px solid ${(props) => props.theme.colors.primary};
			width: ${(props) => (props.block ? "100%" : "auto")};
			${(props) =>
				props.outline &&
				css`
					color: ${props.theme.colors.primary};
				`}
			${(props) => props.size === "large" && ApnaLargeButtonStyles}
			${(props) => props.size === "medium" && ApnaMediumButtonStyles}
			${(props) => props.size === "small" && ApnaSmallButtonStyles}
			${(props) => props.icon && ApnaWithIconButtonStyles}
			${(props) =>
				props.hasBorderRadius &&
				css`
					border-radius: 0;
				`}

			&:hover {
				box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
					0px 8px 8px -4px rgba(24, 39, 75, 0.08);
				background: ${({ theme, outline }) =>
					outline ? theme.colors.primary : "transparent"};
				color: ${({ theme, outline }) =>
					outline ? "#ffffff" : theme.colors.primary};
			}

			${(props) =>
				props.link &&
				css`
					color: #979797;

					height: fit-content;
					border: none;
					background: inherit;
					min-width: auto;

					&:hover {
						box-shadow: none;
						color: #979797;
					}

					${bp.sm} {
						color: #a5a5a5;
						border: 1px solid #a5a5a5;
					}
				`}

			&:disabled {
				background: #dae0e5;
				border: none;
			}
		`,
	},
	vi: {
		primary: css<PropsType>`
			${VIButtonBaseStyles}
			${(props) => props.size === "large" && VILargeButtonStyles}
			${(props) => props.size === "medium" && VIMediumButtonStyles}
			${(props) => props.size === "small" && VISmallButtonStyles}
			${(props) => props.icon && VIWithIconButtonStyles}
			background: ${({ theme, outline }) => (outline ? "transparent" : theme.colors.tertiary)};
			border: 1px solid
				${({ theme, outline }) =>
					outline ? theme.colors.backgroundPrimary : theme.colors.tertiary};
			width: ${(props) => (props.block ? "100%" : "auto")};

			${(props) =>
				props.outline &&
				css`
					color: ${props.theme.colors.tertiary};
					border-color: ${props.theme.colors.tertiary};
				`}

			${(props) =>
				props.link &&
				css`
					/* TODO: get it reviewed from richa why we need padding 0 here */
					/* padding: 0; */
					height: fit-content;
					min-width: auto;
					border: none;
					background: inherit;
					color: ${props.theme.colors.primaryBlack};
				`}
		`,
		secondary: css<PropsType>`
			${VIButtonBaseStyles}
			${(props) => props.size === "large" && VILargeButtonStyles}
			${(props) => props.size === "medium" && VIMediumButtonStyles}
			${(props) => props.size === "small" && VISmallButtonStyles}
			${(props) => props.icon && VIWithIconButtonStyles}
			background: ${({ theme, outline }) => (outline ? "transparent" : theme.colors.primary)};
			border: 1px solid ${(props) => props.theme.colors.primary};
			width: ${(props) => (props.block ? "100%" : "auto")};

			${(props) =>
				props.outline &&
				css`
					color: ${props.theme.colors.primary};
				`}

			&:hover {
				box-shadow: 0px 4px 6px -4px rgba(24, 39, 75, 0.12),
					0px 8px 8px -4px rgba(24, 39, 75, 0.08);
				background: ${({ theme, outline }) =>
					outline ? theme.colors.primaryBlack : "transparent"};
				color: ${({ theme, outline }) =>
					outline ? "#ffffff" : theme.colors.primaryBlack};
			}

			${(props) =>
				props.link &&
				css`
					/* TODO: get it reviewed from richa why we need padding 0 here */
					/* padding: 0; */
					height: fit-content;
					min-width: auto;
					border: none;
					background: inherit;
					color: ${props.theme.colors.primaryBlack};

					&:hover {
						box-shadow: none;
					}
				`}
		`,
	},
});

const Button = styled.button<ButtonProps>`
	${(props: PropsType) => ButtonVariants(props.theme.vendor)(props.variant)}
`;

export default Button;
