import { useEffect, useState } from "react";
import retryPromise from "utils/retryPromise";
import { getJobsAuth } from "../utils/apiClientPrivate";

const useGetJobs = ({
	currentTab,
	selectedCategory,
	selectedArea,
	pageNumber,
	selectedJobType,
}) => {
	const [jobs, setJobs] = useState([]);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(true);

	const fetchJobs = async () => {
		try {
			setLoading(true);

			const filter = `?page=${pageNumber}&row_id=${currentTab.filterKey.replace(
				"{categoryId}",
				selectedCategory.id
			)}${
				selectedJobType?.filterQuery && `&${selectedJobType?.filterQuery}`
			}&area_id=${selectedArea?.id}`;
			const jobsRes = await retryPromise(() => getJobsAuth(filter), 3, 5000);

			setJobs((j) => [...j, ...(jobsRes?.data || [])]);
			if (!jobsRes?.data || jobsRes?.data?.length < 7) setHasMore(false);
			setLoading(false);
		} catch (e) {
			setError(e);
			setLoading(false);
			setJobs([]);
		}
	};

	useEffect(() => {
		if (pageNumber > 0) {
			fetchJobs();
		}
	}, [pageNumber]);

	useEffect(() => {
		setJobs([]);
		setLoading(true);
		setHasMore(true);
		if (selectedCategory && selectedArea?.id) {
			fetchJobs();
		}
	}, [
		currentTab,
		selectedCategory?.id,
		selectedArea?.id,
		selectedJobType?.filterQuery,
	]);

	return { jobs, error, loading, hasMore };
};

export default useGetJobs;
