import { createContext, useEffect, useState } from "react";
import { AuthenticatedUser } from "types/User/AuthenticatedUser";
import Cookies from "js-cookie";
import { USER_AUTH_TOKEN, USER_DATA_EXISTS } from "utils/constants";
import retryPromise from "utils/retryPromise";
import { getUserProfileData } from "utils/apiClientPrivate";

export interface IUserContext {
	user: AuthenticatedUser;
	updateUser: (user: AuthenticatedUser) => void;
}

/* eslint-disable import/prefer-default-export */
export const UserContext = createContext<IUserContext>(null);

export const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState(null);

	const updateUser = (newUserData: AuthenticatedUser) => {
		if (
			newUserData &&
			newUserData?.work_info &&
			newUserData?.work_info?.categories &&
			newUserData?.work_info?.categories?.length !== 0
		) {
			Cookies.set(USER_DATA_EXISTS, true);
		}
		localStorage.setItem("user", JSON.stringify(newUserData));

		setUser(newUserData);
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			const userTokenExists = Cookies.get(USER_AUTH_TOKEN);
			const { id: userId } = JSON.parse(localStorage.getItem("user") || "{}");
			if (userTokenExists && userId) {
				retryPromise(() => getUserProfileData(userId))
					.then((res) => {
						if (res.data) {
							updateUser(res.data);
						}
					})
					.catch((err) => console.log(err));
			} else {
				const userStr = localStorage?.getItem("user");
				setUser(JSON.parse(userStr || "{}"));
			}
		}
	}, []);
	return (
		<UserContext.Provider value={{ user, updateUser }}>
			{children}
		</UserContext.Provider>
	);
};
