import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { USER_AUTH_TOKEN, USER_DATA_EXISTS } from "utils/constants";
import { AuthenticatedUser } from "types/User/AuthenticatedUser";
import retryPromise from "utils/retryPromise";
import { getUserProfileData } from "utils/apiClientPrivate";

const useUser = (fetchUserFromServer = false) => {
	const [user, setUser] = useState<AuthenticatedUser>(null);

	const updateUserCategories = (categories) => {
		const newUserData = {
			...user,
			work_info: { ...user.work_info, categories },
		};

		localStorage.setItem("user", JSON.stringify(newUserData));

		setUser(newUserData);
	};

	const updateUserData = (newUserData) => {
		if (
			newUserData &&
			newUserData?.work_info &&
			newUserData?.work_info?.categories &&
			newUserData?.work_info?.categories?.length !== 0
		) {
			Cookies.set(USER_DATA_EXISTS, true);
		}
		localStorage.setItem("user", JSON.stringify(newUserData));

		setUser(newUserData);
	};

	useEffect(() => {
		if (typeof window !== "undefined") {
			const userTokenExists = Cookies.get(USER_AUTH_TOKEN);
			const { id: userId } = JSON.parse(localStorage.getItem("user") || "{}");
			if (userTokenExists && userId && fetchUserFromServer) {
				retryPromise(() => getUserProfileData(userId))
					.then((res) => {
						if (res.data) {
							updateUserData(res.data);
						}
					})
					.catch((err) => console.log(err));
			} else {
				const userStr = localStorage?.getItem("user");
				setUser(JSON.parse(userStr || "{}"));
			}
		}
	}, []);

	return {
		user,
		updateUserCategories,
		updateUserData,
		isLoggedIn: user && Object.keys(user).length > 0,
	};
};

export default useUser;
