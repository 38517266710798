import { UserContext } from "context/Job/UserContext";
import { useContext } from "react";

const useUserNew = () => {
	const { user = null, updateUser = () => null } = useContext(UserContext) || {};

	const updateUserCategories = (categories) => {
		const newUserData = {
			...user,
			work_info: { ...user.work_info, categories },
		};

		updateUser(newUserData);
	};

	return {
		user,
		updateUser,
		updateUserCategories,
		isLoggedIn: user && Object.keys(user).length > 0,
	};
};

export default useUserNew;
