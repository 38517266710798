const retryPromise = (fn, retriesLeft = 5, interval = 5000, forceRetry = false) => {
	if (process?.env?.NODE_ENV !== "test" || forceRetry) {
		return new Promise((resolve, reject) => {
			fn()
				.then(resolve)
				.catch((error) => {
					setTimeout(() => {
						if (retriesLeft === 1) {
							reject(error);
							return;
						}
						retryPromise(() => fn(), retriesLeft - 1, interval).then(
							resolve,
							reject
						);
					}, interval);
				});
		});
	}
	return fn();
};

module.exports = retryPromise;
